<template>
    <b-container fluid>
        <b-row>
            <b-col lg="12" sm="12">
                <iq-card>
                    <template v-slot:body>
                        <b-overlay :show="loading">
                            <b-row>
                                <b-col lg="12" sm="12">
                                    <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
                                        <b-form @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset">
                                            <b-overlay :show="unitLoad">
                                                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                                                    <table class="table table-bordered">
                                                        <tbody>
                                                            <tr>
                                                                <th>{{$t('bsri_demandDIS.fiscal_year')}}</th>
                                                                <td> {{ $i18n.locale === 'en' ? item.fiscal_year : item.fiscal_year_bn }} </td>
                                                            </tr>
                                                            <tr>
                                                                <th>{{$t('bsri_demandDIS.mill_name')}}</th>
                                                                <td> {{ $i18n.locale === 'en' ? item.mill_name : item.mill_name_bn }} </td>
                                                            </tr>
                                                            <tr>
                                                                <th>{{$t('bsri_demandDIS.subzone_office_name')}}</th>
                                                                <td> {{ $i18n.locale === 'en' ? item.subzone_name : item.subzone_name_bn }} </td>
                                                            </tr>
                                                            <tr>
                                                                <th>{{$t('bsri_demandDIS.center_name')}}</th>
                                                                <td> {{ $i18n.locale === 'en' ? item.center_name : item.center_name_bn }} </td>
                                                            </tr>
                                                            <tr>
                                                                <th>{{$t('bsri_demandDIS.unit_no')}}</th>
                                                                <td> {{ $i18n.locale === 'en' ? item.unit_no : item.unit_no_bn }} </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </b-col>
                                                <b-row style="border-bottom:1px solid #ddd; margin-bottom:10px">
                                                    <b-col xl="12" lg="12" sm="12">
                                                        <b-table-simple bordered>
                                                            <thead class="text-white bg-success">
                                                                <tr>
                                                                    <th class="text-center mytd" >{{ $t('globalTrans.sl_no') }}</th>
                                                                    <th class="mytd" scope="col">{{ $t('bsri_demandDIS.farmer_name') }}</th>
                                                                    <th class="mytd" scope="col">{{ $t('bsri_demandDIS.father_name')}}</th>
                                                                    <!-- <th class="mytd" scope="col">{{ $t('bsri_demandDIS.farmer_village') }}</th> -->
                                                                    <th class="mytd" scope="col">{{ $t('common_config.far_wallet_no') }}</th>
                                                                    <th class="mytd" scope="col">{{ $t('bsri_demandDIS.verified_land') }}</th>
                                                                    <th class="mytd" scope="col">{{ $t('bsri_demandDIS.amount_money') }}</th>
                                                                </tr>
                                                            </thead>
                                                            <b-tbody>
                                                                <template v-for="(infoo, indexx) in formData.demandDetails">
                                                                    <template style="display:inline" :id="indexx"> {{ $i18n.locale === 'bn' ? infoo.cultivation_name_bn : infoo.cultivation_name }} </template>
                                                                    <b-tr v-for="(info, index) in infoo.farmer_list" :key="index">
                                                                        <b-td class="text-center mytd">{{$n(index + 1)}}</b-td>
                                                                        <b-td class="mytd">{{ $i18n.locale === 'bn' ? info.name_bn : info.name }}</b-td>
                                                                        <b-td class="mytd">{{ $i18n.locale === 'bn' ? info.father_name_bn : info.father_name }}</b-td>
                                                                        <!-- <b-td class="mytd">{{ $i18n.locale === 'bn' ? info.village_name_bn : info.village_name }}</b-td> -->
                                                                        <b-td class="mytd">{{ ($i18n.locale == 'bn' ? '০': '0') + $n(info.wallet_no, { useGrouping: false }) }}</b-td>
                                                                        <b-td class="mytd">{{ $n(parseFloat(info.verified_land_quantity), { useGrouping: false }) }}</b-td>
                                                                        <b-td class="mytd">{{ $n(amount(infoo.cultivation_id, info.verified_land_quantity), { useGrouping: false }) }}</b-td>
                                                                    </b-tr>
                                                                </template>
                                                                <b-tr v-if="nodata">
                                                                    <td colspan="5" class="text-center">
                                                                        <span class="text-black">{{ $t('globalTrans.noDataFound') }}</span>
                                                                    </td>
                                                                </b-tr>
                                                            </b-tbody>
                                                        </b-table-simple>
                                                    </b-col>
                                                </b-row>
                                                <div class="row">
                                                    <div class="col-sm-3"></div>
                                                    <div class="col text-right">
                                                        <!-- <b-button type="submit" variant="primary" class="mr-2">{{ draftBtnName }}</b-button>
                                                        &nbsp;
                                                        <b-button type='button' variant="primary" @click="forwardPageShow = !forwardPageShow" class="mr-2">{{ $t('dae_grant_allocation_distribution.forward')}}</b-button>
                                                        &nbsp; -->
                                                        <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-5')">{{ $t('globalTrans.cancel') }}</b-button>
                                                    </div>
                                                </div>
                                                <hr>
                                            </b-overlay>
                                        </b-form>
                                    </ValidationObserver>
                                </b-col>
                            </b-row>
                        </b-overlay>
                        <!-- <pre>{{ formData }}</pre> -->
                    </template>
                </iq-card>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { authServiceBaseUrl, incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { ValidationObserver } from 'vee-validate'
import { subFarApprovalStore, subFarApprovalDraftStore, subFarApproveDetails } from '../../api/routes'
import flatpickr from 'flatpickr'

export default {
    props: ['item'],
    components: {
        ValidationObserver
    },
    data () {
        return {
            forwardPageShow: false,
            loading: false,
            nodata: false,
            unitLoad: false,
            draftBtnName: this.$t('globalTrans.draft'),
            updateBtnName: this.$t('globalTrans.update'),
            formData: {
                fiscal_year_id: 0,
                mill_info_id: 0,
                subzone_office_id: 0,
                center_id: 0,
                unit_id: 0,
                comment: '',
                comment_bn: '',
                list: [],
                demandDetails: [],
                designation_id: 0,
                sender_id: this.$store.state.Auth.authUser.user_id,
                receiver_id: 0
            },
            demandDetails: [],
            employeeList: []
        }
    },
    created () {
        if (this.item) {
            this.formData.fiscal_year = this.item.fiscal_year
            this.formData.fiscal_year_id = this.item.fiscal_year_id
            this.formData.unit_id = this.item.unit_id
            this.formData.crop_id = 1
            this.loadData(this.item)
        }
    },
    watch: {
    },
    mounted () {
        core.index()
        flatpickr('#datepicker', {})
    },
    computed: {
        designationList: function () {
            return this.$store.state.commonObj.designationList.filter(item => item.status === 0 && item.org_id === 8)
        }
    },
    methods: {
        amount (cultivationID, landQuantity) {
            const policyList = this.$store.state.incentiveGrant.commonObj.cultivationPolicy.find(item => item.method_cultivation_id === cultivationID)
            const amount = landQuantity * parseInt(policyList.per_acr_amount)
            return amount
        },
        checked (key, info) {
            if (info.selected === 1) {
                this.formData.list.push(info)
            }
            if (info.selected === 2) {
                this.formData.splice(key, 1)
            }
        },
        async saveData () {
            this.unitLoad = true
            // this.$store.dispatch('mutateCommonProperties', { loading: true })
            let result = null
            const loadingState = { loading: false, listReload: false }
            if (this.forwardPageShow === false) {
                result = await RestApi.postData(incentiveGrantServiceBaseUrl, subFarApprovalDraftStore, this.formData)
            } else {
                result = await RestApi.postData(incentiveGrantServiceBaseUrl, subFarApprovalStore, this.formData)
            }
            loadingState.listReload = true
            this.$store.dispatch('mutateCommonProperties', loadingState)

            if (result.success) {
                this.$store.commit('incentiveGrant/mutateIncentiveGrantServiceProperties', { hasDropdownLoaded: false })
                this.$toast.success({
                title: this.$t('globalTrans.success'),
                message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
                color: '#D6E09B'
                })
                this.unitLoad = false
                this.$bvModal.hide('modal-5')
            } else {
                this.unitLoad = false
                this.$refs.form.setErrors(result.errors)
            }
        },
        loadData (item) {
            this.loading = true
            const params = Object.assign({}, this.item)
            this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
            RestApi.postData(incentiveGrantServiceBaseUrl, subFarApproveDetails, params).then(response => {
                if (response.success) {
                this.formData.demandDetails = this.getRelatinalData(response.data)
                } else {
                this.$store.dispatch('setList', [])
                }
                this.loading = false
                this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
            })
        },
        getRelatinalData (data) {
            const fiscalYearList = this.$store.state.commonObj.fiscalYearList
            const cultivationMethodList = this.$store.state.incentiveGrant.commonObj.cultivationMethodList
            const millInfoList = this.$store.state.incentiveGrant.commonObj.millInfoList
            const subzoneOfficeList = this.$store.state.incentiveGrant.commonObj.subzoneOfficeList
            const centerList = this.$store.state.incentiveGrant.commonObj.centerList
            const unitList = this.$store.state.incentiveGrant.commonObj.unitList
            const listData = data.map(item => {
                const fiscalYearListObject = fiscalYearList.find(fiscal => fiscal.value === item.fiscal_year_id)
                const millDataObject = millInfoList.find(mill => mill.value === item.mill_info_id)
                const cultivationMethodListDataObject = cultivationMethodList.find(methodItem => methodItem.value === item.cultivation_id)
                const subzoneObject = subzoneOfficeList.find(zone => zone.value === item.subzone_office_id)
                const centerObject = centerList.find(center => center.value === item.center_id)
                const unitObject = unitList.find(unit => unit.value === item.unit_id)
                const ojbData = {
                    fiscal_year: fiscalYearListObject !== undefined ? fiscalYearListObject.text_en : '',
                    fiscal_year_bn: fiscalYearListObject !== undefined ? fiscalYearListObject.text_bn : '',
                    mill_name: millDataObject !== undefined ? millDataObject.text_en : '',
                    mill_name_bn: millDataObject !== undefined ? millDataObject.text_bn : '',
                    cultivation_name: cultivationMethodListDataObject !== undefined ? cultivationMethodListDataObject.text_en : '',
                    cultivation_name_bn: cultivationMethodListDataObject !== undefined ? cultivationMethodListDataObject.text_bn : '',
                    subzone_name: subzoneObject !== undefined ? subzoneObject.text_en : '',
                    subzone_name_bn: subzoneObject !== undefined ? subzoneObject.text_bn : '',
                    center_name: centerObject !== undefined ? centerObject.text_en : '',
                    center_name_bn: centerObject !== undefined ? centerObject.text_bn : '',
                    unit_no: unitObject !== undefined ? unitObject.text_en : '',
                    unit_no_bn: unitObject !== undefined ? unitObject.text_en : ''
                }
                return Object.assign({}, item, ojbData)
            })
            return listData
        },
        async getEmployeeList (index) {
            this.officerLoading = true
            const designationId = this.formData.designation_id
            await RestApi.getData(authServiceBaseUrl, 'user/list-by-designation', { designation_id: designationId }).then(response => {
                if (response.success) {
                const designationUserList = response.data.filter(user => user.designation_id === designationId)
                const employeListItems = designationUserList.map((obj, index) => {
                    if (this.$i18n.locale === 'bn') {
                    return { value: obj.user_id, text: obj.name_bn }
                    } else {
                    return { value: obj.user_id, text: obj.name }
                    }
                })
                this.employeeList = employeListItems
                }
               this.officerLoading = false
            })
        }
    }
}
</script>

<style scoped>
    table tbody td:last-child {
        width: auto !important;
    }
</style>
